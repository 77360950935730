<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <loading :active.sync="isPageLoading" is-full-page></loading>
        <div v-if="!isPageLoading">
          <div class="page-title">
            <h1>Owners</h1>
          </div>
          <div class="page-content">
            <div class="form-group row">
              <label for="searchKeyword" class="col-sm-2 col-form-label">Search by Keyword</label>
              <div class="col-sm-8">
                <input type="text" id="searchKeyword" name="searchKeyword" class="form-control" autocomplete="off" v-model="searchCriteria.keyword" />
              </div>
              <div class="col-sm-2">
                <button v-if="$permissions().hasEditOwnerPermission" type="button" class="btn btn-primary" @click="newOwner">
                  <i class="fas fa-plus-circle"></i> Add New
                </button>
              </div>
            </div>
            <data-table :data="searchResults.results" :columns="columns" :total="searchResults.total" :query="searchCriteria"
                :isLoading="isDataLoading" :column-filter="false">
              <template v-slot:name="{ row }">{{ row.name }}</template>
              <template v-slot:address="{ row }">{{ getAddressDisplay(row.address) || '---' }}</template>
              <template v-slot:phone="{ row }">{{ row.phone || '---' }}</template>
              <template v-slot:edit="{ row }">
                <a href="#" role="button" class="d-flex justify-content-between align-items-center flex-column" title="Edit"
                    v-if="$permissions().hasEditOwnerPermission" @click.prevent="editOwner(row.id)">
                  <img class="mb-1" src="@/assets/document.svg" alt />
                  <span class="small-grey">Edit</span>
                </a>
              </template>
              <template v-slot:delete="{ row }">
                <a href="#" role="button" class="d-flex justify-content-between align-items-center flex-column" title="Delete"
                    v-if="$permissions().hasDeleteOwnerPermission" @click.prevent="deleteOwner(row.id)">
                  <img class="mb-1" src="@/assets/red_x.svg" alt />
                  <span class="small-grey">Delete</span>
                </a>
              </template>
              <template v-slot:loading>
                <loading :active.sync="isDataLoading" :is-full-page="false"></loading>
              </template>
              <template v-slot:no-records>
                <div class="alert alert-warning" role="alert">No records found.</div>
              </template>
            </data-table>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-owner" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <owner @save-action="onOwnerSave"></owner>
    </b-modal>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MessageBox from "@/utils/messagebox";
import Toast from "@/utils/toast";
import AddressDisplayMixin from "@/mixins/addressDisplay";
import { OwnerService } from "@/services/";
import debounce from "lodash/debounce";

import { DataTable } from "@/components/datatable/";

const defaultSearchCriteria = {
  page: 1,
  pageSize: 20
};

export default {
  name: "configuration-users",
  components: {
    'app-navbar': () => import('@/components/app-navbar.vue'),
    'data-table': DataTable,
    'owner': () => import('@/views/configuration/components/owner.vue')
  },
  mixins: [AddressDisplayMixin],
  data: function() {
    return {
      isPageLoading: true,
      isDataLoading: false,
      searchResults: { total: 0, results: [] },
      searchCriteria: null,
      columns: [
        { display: "Name", field: "name", sortable: false },
        { display: "Address", field: "address", sortable: false },
        { display: "Phone", field: "phone", sortable: false },
        { field: 'edit' },
        { field: 'delete' }
      ],
    };
  },
  watch: {
    "searchCriteria.page": function(newValue, oldValue) {
      if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.getSearchResults();
      }
    },
    "searchCriteria.pageSize": function(newValue, oldValue) {
      if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.searchCriteria.page = 1;
        this.getSearchResults();
      }
    },
    "searchCriteria.sortDirection": function(newValue, oldValue) {
      if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.getSearchResults();
      }
    },
    "searchCriteria.sortOrder": function(newValue, oldValue) {
      if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.getSearchResults();
      }
    },
    "searchCriteria.keyword": debounce(
      function(newValue, oldValue) {
        if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
          this.getSearchResults()
        }
      },
      500, false)
  },
  computed: {
    ...mapGetters(["getOwners", "getProvinces"])
  },
  methods: {
    newOwner() {
      this.resetOwner().then(() => {
        this.$bvModal.show("bv-modal-owner");
      });
    },
    editOwner(id) {
      this.fetchOwner(id).then(() => {
        this.$bvModal.show("bv-modal-owner");
      });
    },
    async deleteOwner(id) {
      let confirm = await MessageBox.confirm(this, "Remove Owner", "Are you sure you want to remove owner?");

      if (confirm) {
        this.removeOwner(id).then(() => {
          Toast.success(this, "Successfully removed owner!");
        });
      }
    },
    getSearchResults() {
      if (!this.isPageLoading) {
        this.isDataLoading = true;
      }

      return OwnerService.search(this.searchCriteria)
        .then(response => {
          this.searchResults = response.data;
          this.isDataLoading = false;
        })
        .catch(() => {
          this.isDataLoading = false;
          Toast.danger(this, "Oops! An error has occured.");
        });
    },
    onOwnerSave () {
      this.getSearchResults();
    },
    ...mapActions(["fetchOwner", "fetchProvinces", "removeOwner", "resetOwner"])
  },
  beforeMount() {
    this.searchCriteria = { ...defaultSearchCriteria };
  },
  mounted: function() {
    Promise.all([
      this.fetchProvinces(),
      this.getSearchResults()
    ])
    .then(() => {
      this.isPageLoading = false;
    });
  }
};
</script>
